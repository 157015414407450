import React from 'react';
import { string, bool } from 'prop-types';
import { Button, IconButton } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';

function InstagramButton({ colorScheme, onlyIcon, id, text, ...rest }) {
  const handleRedirect = () => {
    if (!id) return '';

    window.open(`https://instagram.com/${id}`, '_blank');
  };

  const commonProps = {
    colorScheme,
    onClick: handleRedirect,
  };

  if (onlyIcon) {
    return (
      <IconButton
        isRound
        aria-label="instagram icon"
        icon={<FaInstagram color="white" />}
        {...commonProps}
        {...rest}
      />
    );
  }

  return (
    <Button
      leftIcon={<FaInstagram />}
      variant="solid"
      size="xs"
      borderRadius="8px"
      padding="8px 16px"
      {...commonProps}
      {...rest}
    >
      {text}
    </Button>
  );
}

InstagramButton.propTypes = {
  colorScheme: string,
  id: string,
  onlyIcon: bool,
  text: string,
};

InstagramButton.defaultProps = {
  colorScheme: 'button.secondaryDark',
  id: '',
  onlyIcon: false,
  text: 'Instagram',
};

export default InstagramButton;
